import React, { FunctionComponent } from "react"
import { graphql, PageProps } from "gatsby"
import * as SCM from "../types"
import SEO from "../components/SEO"
import Layout from "../components/Layout"
import Collection from "../components/Collection"
import ComingSoon from "../components/Comingsoon"

type Props = PageProps & {
  data: {
    collectionPage: SCM.CollectionPage
    comingSoonPage: SCM.ComingSoonPage
  }
}

const CollectionPageTemplate: FunctionComponent<Props> = props => {
  const collectionPage = props.data.collectionPage
  const comingSoonPage = props.data.comingSoonPage

  return (
    <Layout>
      <SEO
        locale={collectionPage.locale}
        uri={props.uri}
        products={
          collectionPage.comingSoon!
            ? collectionPage.collection.products
            : undefined
        }
        breadcrumb={[
          {
            position: 1,
            name: "Catalogue",
            slug: "/catalogue",
          },
          {
            position: 2,
            name: collectionPage.collection.name,
            slug: collectionPage.slug,
          },
        ]}
        {...collectionPage.seo}
      />
      {collectionPage.comingSoon ? (
        <ComingSoon {...comingSoonPage} />
      ) : (
        <Collection {...collectionPage.collection} />
      )}
    </Layout>
  )
}

export default CollectionPageTemplate

export const query = graphql`
  query CollectionPageQuery($slug: String!) {
    collectionPage(slug: { eq: $slug }) {
      ...collectionPageFragment
    }
    comingSoonPage {
      ...comingSoonPageFragment
    }
  }
`
